import React from 'react';
import * as styles from './col.module.scss';

function Col({col = 12,md = 12,sm = 12,lg = 12,xl = 12,children, className}) {
  const [sM,mD,lG,xL] = cols([sm,md,lg,xl])
  return (
    <div style={{"--col":col,"--sm":sM,"--md":mD,"--lg":lG,"--xl":xL}} className={`${styles.col} ${className ? className : ''}`}>
      {children}
    </div>
  );
}

const cols = (arr) =>{
  // in arr [sm,md,lg,xl]
  let tmp = 12;

  let cols = arr.map(i => {
    if (12 > i){
      tmp = i;
    }
    return tmp;
  })
  return cols;
}


export default Col;