// extracted by mini-css-extract-plugin
export var bmOverlay = "menu-module--bm-overlay--3Rb5X";
export var bmMenuWrap = "menu-module--bm-menu-wrap--2C4ON";
export var nav = "menu-module--nav--1Xwyc";
export var nav__contacts = "menu-module--nav__contacts--2OmaV";
export var active = "menu-module--active--Ja2D_";
export var menuCross = "menu-module--menu-cross--1VEji";
export var menuWrapper = "menu-module--menu-wrapper--2LbwC";
export var bmBurgerButton = "menu-module--bm-burger-button--MQVlK";
export var bmItem = "menu-module--bm-item--1TsWH";
export var menuDesktop = "menu-module--menu-desktop--1M0la";
export var DropDown = "menu-module--DropDown--UGixc";