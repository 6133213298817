import React from 'react';
import {Button} from "../Atoms/Buttons/Button";



function StartWork() {
    
    
    return (
        <section className="site_section container">
            <div className="col-2">
                <div>
                    <div className="article__header">
                        <h2>Обсудим ваш проект?</h2>
                        <p>Оставьте заявку и мы свяжемся с вами в ближайшее время, ответим на все вопросы </p>
                    </div>                    
                    <form name="Форма Футера"  method="post" action="/thankyou/" netlify-honeypot="bo-field" data-netlify="true" >
                        <input type="hidden" name="bo-field" />
                        <input type="hidden" name="form-name" value="Форма Футера"/>
                        <input type="text" name="name" placeholder="Как к вам обращаться?" required/>
                        <input type="tel" name="phone" placeholder="Введите номер телефона" required/>
                        <textarea type="text" name="message" placeholder="Опишите ваш проект"></textarea>
                        <Button type="submit" primary>Начнем работу</Button>
                    </form>
                </div>
                <div>
                    <img src="../../imgs/forminfooter.svg" alt="Заказать сайт" lazy/>             
                </div>
            </div>
        </section>
        
    );
}

export default StartWork;