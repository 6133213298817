import React from 'react';
import { slide as MenuInner } from 'react-burger-menu';
import { Link } from 'gatsby';
import * as styles from './menu.module.scss'
import DropDownList from "../../Molecules/DropDownList/DropDownList";




function Menu() {

    const menuButton = <div className="menu-button" style={"width:48px; heigth:48px;"}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>;
    const cross = <div className="menu-cross">
                    <span></span>
                    <span></span>
                </div>;

    return (
        
        <nav className={styles.nav}>
          <div className={styles.menuDesktop}>
            <ul>
              <li>
                <Link to="/" activeClassName={styles.active}>Главная</Link>
              </li>
              <li>
                <DropDownList header="Услуги" className={styles.DropDown}>
                  <ul>
                    <li>
                      <b>Web-сайты</b>
                      <ul>
                        <li>
                          <Link to="/create-sites/" activeClassName={styles.active}>Разработка сайтов</Link>
                        </li>
                        <li>
                          <Link to="/create-sites/landing-page/" activeClassName={styles.active}>Лендинги</Link>
                        </li>
                        <li>
                          <Link to="/create-sites/corporate/" activeClassName={styles.active}>Сайты компаний</Link>
                        </li>
                        <li>
                          <Link to="/create-sites/e-commerce/" activeClassName={styles.active}>Интернет магазины</Link>
                        </li>
                        <li>
                          <Link to="/support/" activeClassName={styles.active}>Поддержка сайтов</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Маркетинг и реклама</b>
                      <ul>
                        <li>
                          <Link to="/context-advertising/" activeClassName={styles.active}>Контекстная реклама</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </DropDownList>
              </li>
              <li>
                <Link to="/portfolio/" activeClassName={styles.active}>Портфолио</Link>
              </li>
              <li>
                <Link to="/about-us/" activeClassName={styles.active}>О нас</Link>
              </li>
            </ul>




          </div>

            {/*<ul className={styles.menuDesktop} key={0}>*/}
            {/*    <li>*/}
            {/*        <Link to="/" activeClassName={styles.active}>Главная</Link>*/}
            {/*    </li>*/}
            {/*    <li className={styles.hasDropDown}>*/}
            {/*        <Link to="#" activeClassName={styles.active}>Услуги</Link>*/}
            {/*        <div>*/}

            {/*                <ul>*/}
            {/*                    <li>*/}
            {/*                        <Link to="/create-sites/" activeClassName={styles.active}>Разработка сайтов</Link>*/}
            {/*                        <ul>*/}
            {/*                            <li>*/}
            {/*                                <Link to="/create-sites/landing-page/" activeClassName={styles.active}>Лендинги</Link>*/}
            {/*                            </li>*/}
            {/*                            <li>*/}
            {/*                                <Link to="/create-sites/corporate/" activeClassName={styles.active}>Сайты компаний</Link>*/}
            {/*                            </li>*/}
            {/*                            <li>*/}
            {/*                                <Link to="/create-sites/e-commerce/" activeClassName={styles.active}>Интернет магазины</Link>*/}
            {/*                            </li>*/}
            {/*                        </ul>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <Link to="/support/" activeClassName={styles.active}>Поддержка сайтов</Link>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <Link to="/context-advertising/" activeClassName={styles.active}>Контекстная реклама</Link>*/}
            {/*                    </li>*/}
            {/*                </ul>*/}

            {/*        </div>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <Link to="/portfolio/" activeClassName={styles.active}>Портфолио</Link>*/}
            {/*    </li>*/}
            {/*    /!*<li>*/}
            {/*        <Link to="/" activeClassName={styles.active}>Цены</Link>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <Link to="/" activeClassName={styles.active}>Решения</Link>*/}
            {/*    </li>*!/*/}
            {/*    <li>*/}
            {/*        <Link to="/about-us/" activeClassName={styles.active}>О нас</Link>*/}
            {/*    </li>*/}
            {/*    /!*<li>*/}
            {/*        <Link to="/contact/" activeClassName={styles.active}>Контакты</Link>*/}
            {/*    </li>*!/*/}
            {/*</ul>*/}


            <ul className="menu-desktop">
                <li className="nav__contacts">
                    <a className="phone" href="tel:+79956516590">8(995)651-65-90</a>
                    {/*<a className="call-back-menu" >Заказать звонок</a>*/}
                </li>
                <li>
                    
                </li>
            </ul>
            <div className="menu-wrapper menu-mobile" key={1}>
                <MenuInner right customBurgerIcon={menuButton} pageWrapId={"page-wrap"} customCrossIcon={cross}>
                    <ul className="menu-desktop" key={0}>
                        <li>
                            <Link to="/" activeClassName={styles.active}>Главная</Link>
                        </li>
                        <li>
                            <Link to="#" activeClassName={styles.active}>Услуги</Link>
                            <ul>
                                <li>
                                    <Link to="/create-sites/" activeClassName={styles.active}>Разработка сайтов</Link>
                                    <ul className="last">
                                        <li>
                                            <Link to="/create-sites/landing-page/" activeClassName={styles.active}>Разработка лендингов</Link>
                                        </li>
                                        <li>
                                            <Link to="/create-sites/corporate/" activeClassName={styles.active}>Создание сайтов компаний</Link>
                                        </li>
                                        <li>
                                            <Link to="/create-sites/e-commerce/" activeClassName={styles.active}>Создание интернет магазина</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/support/" activeClassName={styles.active}>Поддержка сайтов</Link>
                                </li>
                                <li>
                                    <Link to="/context-advertising/" activeClassName={styles.active}>Контекстная реклама</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/portfolio/" activeClassName={styles.active}>Портфолио</Link>
                        </li>
                        {/*<li>
                            <Link to="/" activeClassName={styles.active}>Цены</Link>
                        </li>
                        <li>
                            <Link to="/" activeClassName={styles.active}>Решения</Link>
                        </li>*/}
                        <li>
                            <Link to="/about-us/" activeClassName={styles.active}>О нас</Link>
                        </li>
                        {/*<li>
                            <Link to="/contact/" activeClassName={styles.active}>Контакты</Link>
                        </li>*/}
                    </ul>
                    <ul className="menu-desktop">
                        <li className="nav__contacts">
                            <a className="phone" href="tel:+79956516590">8(995)651-65-90</a>
                            {/*<a className="call-back-menu" >Заказать звонок</a>*/}
                        </li>
                        <li>
                            
                        </li>
                    </ul>
                </MenuInner>
            </div>
            </nav>
    );
}

export default Menu;

/**/