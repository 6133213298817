import React from 'react';

function Main(props) {
    return (
        <main className="main" id="page-wrap">
            {props.children}
        </main>
    );
}

export default Main;