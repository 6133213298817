import React from 'react';
import Client from './Client'

function OurClients(props) {
    return (
        <article className="site_section">
            <section className="container">
                <h2>Наши клиенты</h2>
                <div className="col-4">
                    <Client img={"../../imgs/bmlogo.png"}/>
                    <Client  img={"../../imgs/dexenlogo.svg"}/>
                    <Client img={"../../imgs/alexfittenslogo.svg"}/>
                    <Client img={"../../imgs/reginaslogo.svg"}/>
                    {props.children}
                </div>                
            </section>
        </article>
    );
}

export default OurClients;