import React from 'react';
import * as styles from './PageHeader.module.scss'
import Modal from "../../Molecules/Modal/Modal";
import Cards from './Cards';
import {Container} from "../../Atoms/greeds";



function PageHeader({landing,h1,p,modalButton,modalContent,сards,children, col}) {
    return (
        <article className={`${styles.pageHero} site_section ${landing ? "landing " : ""}`}>
            <Container>
              <h1>{h1}</h1>
              {p && <p className={styles.pageHero__desc}>{p}</p>}
              {modalButton &&
              <Modal btnText="Получить консультацию" primary modalHeader="Получите бесплатную консультацию" >
                {modalContent}
              </Modal>}
              {сards && <Cards col={col} cards={сards}/>}
              {children}
            </Container>
        </article>
    );
}

export default PageHeader;