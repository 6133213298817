import React from 'react';
import OurClients from '../OurClients/OurClients';
import FooterForm from '../forms/FooterForm'
import { Link } from 'gatsby';



function Footer(props) {
    return (
        <footer className="footer">
            <FooterForm/>
            <section className="site_section footer__content">
                <div className="container">
                    <div className="col-4">
                        <div>
                            <img className="footer__logo" src="../../imgs/LogoADSWhite.svg" alt="Разработка сайтов" lazy/>
                            <span className="footer__slogan">С заботой о вашем успехе</span>
                        </div>
                        <div>
                            <Link to="/">Домашняя</Link>
                        </div>
                        <div>
                            <Link to="/create-sites/">Разработка сайтов</Link>
                            <Link to="/create-sites/landing-page/">Разработка лендингов</Link>
                        </div>
                        <div>
                            <Link to="/context-advertising/">Контекстная реклама</Link>
                            
                        </div>
                    </div>
                    <div className="col-4">
                        <div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                        <div>
                            
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
}

export default Footer;