import React from 'react';
import * as styles from './row.module.scss'

function Row({children, className}) {
  return (
    <div className={`${styles.row} ${className}`}>
      {children}
    </div>
  );
}

export default Row;