import React, { useState } from 'react';
import {Button} from '../../Atoms/Buttons/Button';

function Modal(props) {
    const {children, btnText, modalHeader} = props;
    const [isOpen, toggle] = useState(false);
    const changeOpen = (event) => {
        
        if (isOpen && event.target.dataset.clicker) {
            toggle(false)
        } else {
            toggle(true)
        }
    }
    return (
        <>
            <Button {...props} onClick={(event) => changeOpen(event)}>{btnText}</Button>
            <div className={`modal-wraper ${isOpen ? "":"hidden"}`} data-clicker={true} onClick={(event) => changeOpen(event)}>
                <div className={`modal ${isOpen ? "modal_animation":""}`}>
                    <div className="modal_header">
                        <p>{modalHeader}</p>
                        <div className="modal_close" data-clicker={true}></div>
                    </div>
                    <div className="modal_body">
                        {children}
                    </div>
                </div>
            </div>
                 
        </>
    );
}

export default Modal;