import React from 'react';
import { Link } from 'gatsby';

const Logo = () => (
        <div className="logo">
            <Link to="/">
                <svg width="180px" height="auto" viewBox="0 0 750 173" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M204 152.526V106.156H228.405C237.041 106.156 244.018 108.502 249.337 113.196C254.656 117.826 257.316 124.084 257.316 131.969C257.316 139.854 254.656 146.143 249.337 150.836C244.018 155.467 237.041 157.782 228.405 157.782H209.257C207.817 157.782 206.566 157.281 205.502 156.28C204.501 155.216 204 153.965 204 152.526ZM218.643 146.143H227.842C232.285 146.143 235.821 144.891 238.449 142.388C241.14 139.822 242.485 136.349 242.485 131.969C242.485 127.526 241.14 124.053 238.449 121.55C235.821 119.047 232.285 117.795 227.842 117.795H208.224L218.643 123.427V146.143Z" fill="#505762"/>
                    <path d="M271.483 113.29C268.98 113.29 266.915 112.601 265.288 111.225C263.724 109.785 262.941 108.002 262.941 105.874C262.941 103.747 263.724 101.994 265.288 100.618C266.915 99.1784 268.98 98.4587 271.483 98.4587C273.173 98.4587 274.675 98.7716 275.989 99.3974C277.303 99.9606 278.304 100.805 278.992 101.932C279.681 102.996 280.025 104.247 280.025 105.686C280.025 107.126 279.681 108.44 278.992 109.629C278.304 110.755 277.303 111.663 275.989 112.351C274.675 112.977 273.173 113.29 271.483 113.29ZM264.443 152.619V117.795H273.83C275.144 117.795 276.239 118.233 277.115 119.109C278.054 119.985 278.523 121.08 278.523 122.395V157.782H269.606C268.167 157.782 266.946 157.281 265.945 156.28C264.944 155.279 264.443 154.059 264.443 152.619Z" fill="#505762"/>
                    <path d="M325.472 117.795C327.036 117.795 328.381 118.358 329.508 119.485C330.697 120.611 331.291 121.988 331.291 123.615V150.461C331.291 154.215 330.759 157.501 329.696 160.316C328.694 163.132 327.161 165.448 325.096 167.263C323.094 169.077 320.653 170.423 317.775 171.299C314.896 172.238 311.579 172.707 307.825 172.707C299.502 172.707 292.9 170.955 288.019 167.45L290.835 161.912C291.398 160.848 292.243 160.097 293.369 159.659C294.558 159.284 295.685 159.347 296.749 159.847C299.94 161.224 303.225 161.912 306.604 161.912C313.738 161.912 317.305 158.721 317.305 152.338V150.93C314.552 154.121 310.578 155.717 305.384 155.717C299.94 155.717 295.309 153.934 291.492 150.367C287.675 146.8 285.766 142.138 285.766 136.381C285.766 130.624 287.675 125.962 291.492 122.395C295.309 118.828 299.94 117.044 305.384 117.044C308.45 117.044 311.642 117.826 314.959 119.391C318.338 120.955 320.997 123.208 322.937 126.149L317.962 117.795H325.472ZM302.38 142.294C304.07 143.796 306.166 144.547 308.67 144.547C311.235 144.547 313.332 143.796 314.959 142.294C316.586 140.73 317.399 138.759 317.399 136.381C317.399 133.94 316.586 131.969 314.959 130.467C313.332 128.965 311.235 128.214 308.67 128.214C306.166 128.214 304.07 128.965 302.38 130.467C300.753 131.969 299.94 133.94 299.94 136.381C299.94 138.759 300.753 140.73 302.38 142.294Z" fill="#505762"/>
                    <path d="M345.549 113.29C343.046 113.29 340.981 112.601 339.354 111.225C337.79 109.785 337.008 108.002 337.008 105.874C337.008 103.747 337.79 101.994 339.354 100.618C340.981 99.1784 343.046 98.4587 345.549 98.4587C347.239 98.4587 348.741 98.7716 350.055 99.3974C351.369 99.9606 352.37 100.805 353.059 101.932C353.747 102.996 354.091 104.247 354.091 105.686C354.091 107.126 353.747 108.44 353.059 109.629C352.37 110.755 351.369 111.663 350.055 112.351C348.741 112.977 347.239 113.29 345.549 113.29ZM338.509 152.619V117.795H347.896C349.21 117.795 350.305 118.233 351.181 119.109C352.12 119.985 352.589 121.08 352.589 122.395V157.782H343.672C342.233 157.782 341.012 157.281 340.011 156.28C339.01 155.279 338.509 154.059 338.509 152.619Z" fill="#505762"/>
                    <path d="M389.4 149.897L391.653 156.093C389.275 157.657 386.021 158.439 381.891 158.439C376.697 158.439 372.692 157.156 369.876 154.591C367.06 152.025 365.652 148.208 365.652 143.139V129.716H359.832V119.203H375.133L365.652 115.261V108.784H375.977C376.666 108.784 377.292 108.972 377.855 109.347C378.418 109.66 378.856 110.098 379.169 110.661C379.482 111.225 379.638 111.85 379.638 112.539V119.203H388.837V129.716H379.638V142.951C379.638 144.453 380.014 145.611 380.765 146.424C381.578 147.175 382.611 147.551 383.862 147.551C384.3 147.551 384.707 147.519 385.082 147.457C386.084 147.332 386.96 147.488 387.711 147.926C388.524 148.364 389.087 149.021 389.4 149.897Z" fill="#505762"/>
                    <path d="M416.66 117.044C430.302 117.044 437.123 123.208 437.123 135.536V157.782H427.361C426.422 157.782 425.64 157.469 425.014 156.843C424.389 156.155 424.076 155.342 424.076 154.403V152.619C422.011 156.499 418.068 158.439 412.249 158.439C407.555 158.439 403.894 157.282 401.266 154.966C398.638 152.651 397.324 149.678 397.324 146.049C397.324 142.232 398.763 139.291 401.642 137.225C404.583 135.16 409.057 134.128 415.065 134.128H430.834C430.834 133.878 429.489 133.533 426.798 133.095C424.107 132.595 422.73 132.251 422.668 132.063C421.729 129.184 419.038 127.745 414.595 127.745C412.28 127.745 409.964 128.183 407.649 129.059C406.46 129.56 405.271 129.591 404.082 129.153C402.956 128.715 402.111 127.933 401.548 126.806L399.013 121.738C401.266 120.236 403.957 119.078 407.086 118.264C410.277 117.451 413.469 117.044 416.66 117.044ZM416.191 149.522C419.695 149.522 421.979 147.989 423.043 144.922V141.449H417.036C412.968 141.449 410.934 142.795 410.934 145.486C410.934 146.675 411.372 147.645 412.249 148.396C413.187 149.146 414.501 149.522 416.191 149.522Z" fill="#505762"/>
                    <path d="M442.79 102.964H453.022C454.085 102.964 454.962 103.34 455.65 104.091C456.401 104.842 456.776 105.718 456.776 106.719V157.782H446.169C445.231 157.782 444.417 157.469 443.729 156.843C443.103 156.155 442.79 155.342 442.79 154.403V102.964Z" fill="#505762"/>
                    <path d="M496.861 155.435C495.609 154.935 494.733 154.059 494.233 152.807C493.795 151.556 493.857 150.304 494.42 149.053L497.424 142.388C498.926 143.389 500.615 144.265 502.493 145.016C504.37 145.767 506.341 146.362 508.406 146.8C510.471 147.238 512.474 147.457 514.414 147.457C520.046 147.457 522.862 146.049 522.862 143.233C522.862 142.294 522.424 141.481 521.548 140.792C520.734 140.104 519.639 139.572 518.262 139.197C516.886 138.759 515.321 138.352 513.569 137.976C511.817 137.538 510.002 137.132 508.125 136.756C506.31 136.318 504.527 135.692 502.774 134.879C501.022 134.065 499.458 133.158 498.081 132.157C496.704 131.093 495.578 129.685 494.702 127.933C493.888 126.181 493.482 124.147 493.482 121.831C493.482 116.95 495.453 112.945 499.395 109.817C503.338 106.688 509.001 105.123 516.385 105.123C523.707 105.123 529.933 106.625 535.064 109.629L532.342 116.293C531.904 117.357 531.122 118.139 529.996 118.64C528.869 119.078 527.774 119.078 526.71 118.64C523.081 117.201 519.608 116.481 516.291 116.481C510.722 116.481 507.937 118.014 507.937 121.08C507.937 122.332 508.657 123.365 510.096 124.178C511.535 124.929 513.35 125.492 515.54 125.868C517.73 126.181 520.077 126.712 522.58 127.463C525.146 128.152 527.492 128.997 529.62 129.998C531.81 130.936 533.625 132.47 535.064 134.597C536.504 136.662 537.223 139.165 537.223 142.106C537.223 146.925 535.252 150.93 531.31 154.121C527.367 157.25 521.704 158.815 514.32 158.815C507.874 158.815 502.055 157.688 496.861 155.435Z" fill="#505762"/>
                    <path d="M572.549 149.897L574.802 156.093C572.424 157.657 569.17 158.439 565.04 158.439C559.846 158.439 555.841 157.156 553.025 154.591C550.209 152.025 548.801 148.208 548.801 143.139V129.716H542.981V119.203H558.282L548.801 115.261V108.784H559.126C559.815 108.784 560.44 108.972 561.004 109.347C561.567 109.66 562.005 110.098 562.318 110.661C562.631 111.225 562.787 111.85 562.787 112.539V119.203H571.986V129.716H562.787V142.951C562.787 144.453 563.163 145.611 563.913 146.424C564.727 147.175 565.759 147.551 567.011 147.551C567.449 147.551 567.856 147.519 568.231 147.457C569.233 147.332 570.109 147.488 570.86 147.926C571.673 148.364 572.236 149.021 572.549 149.897Z" fill="#505762"/>
                    <path d="M622.712 117.701V153.37C622.712 154.559 622.274 155.592 621.398 156.468C620.522 157.344 619.49 157.782 618.301 157.782H609.29L613.983 147.551C611.355 151.431 608.601 154.215 605.723 155.905C602.844 157.594 600.091 158.439 597.463 158.439C592.269 158.439 588.138 156.906 585.072 153.84C582.006 150.773 580.473 146.205 580.473 140.135V117.795H590.798C591.862 117.795 592.738 118.139 593.426 118.828C594.115 119.516 594.459 120.392 594.459 121.456V137.789C594.459 143.546 596.68 146.424 601.123 146.424C603.376 146.424 605.191 145.673 606.568 144.172C607.944 142.607 608.633 140.229 608.633 137.038V122.488C608.633 121.174 609.102 120.048 610.041 119.109C610.979 118.171 612.106 117.701 613.42 117.701H622.712Z" fill="#505762"/>
                    <path d="M673.097 102.964V154.121C673.097 155.123 672.752 155.999 672.064 156.75C671.376 157.438 670.531 157.782 669.53 157.782H659.768V153.746C657.139 156.875 653.228 158.439 648.034 158.439C642.402 158.439 637.709 156.531 633.954 152.713C630.2 148.896 628.322 143.89 628.322 137.695C628.322 131.5 630.2 126.525 633.954 122.77C637.709 118.953 642.402 117.044 648.034 117.044C654.292 117.044 659.705 119.798 664.273 125.304L659.111 117.795V107.001C659.111 105.874 659.486 104.936 660.237 104.185C661.05 103.371 662.02 102.964 663.147 102.964H673.097ZM650.944 147.269C653.385 147.269 655.387 146.424 656.952 144.735C658.516 142.983 659.298 140.636 659.298 137.695C659.298 134.754 658.516 132.438 656.952 130.749C655.387 129.059 653.385 128.214 650.944 128.214C648.504 128.214 646.501 129.059 644.937 130.749C643.372 132.438 642.59 134.754 642.59 137.695C642.59 140.698 643.372 143.045 644.937 144.735C646.501 146.424 648.504 147.269 650.944 147.269Z" fill="#505762"/>
                    <path d="M687.372 113.29C684.869 113.29 682.804 112.601 681.177 111.225C679.613 109.785 678.831 108.002 678.831 105.874C678.831 103.747 679.613 101.994 681.177 100.618C682.804 99.1784 684.869 98.4587 687.372 98.4587C689.062 98.4587 690.564 98.7716 691.878 99.3974C693.192 99.9606 694.193 100.805 694.882 101.932C695.57 102.996 695.914 104.247 695.914 105.686C695.914 107.126 695.57 108.44 694.882 109.629C694.193 110.755 693.192 111.663 691.878 112.351C690.564 112.977 689.062 113.29 687.372 113.29ZM680.332 152.619V117.795H689.719C691.033 117.795 692.128 118.233 693.004 119.109C693.943 119.985 694.412 121.08 694.412 122.395V157.782H685.495C684.056 157.782 682.835 157.281 681.834 156.28C680.833 155.279 680.332 154.059 680.332 152.619Z" fill="#505762"/>
                    <path d="M724.277 158.439C717.707 158.439 712.294 156.499 708.038 152.619C703.783 148.677 701.655 143.702 701.655 137.695C701.655 131.687 703.783 126.744 708.038 122.864C712.294 118.984 717.707 117.044 724.277 117.044C730.848 117.044 736.229 118.984 740.422 122.864C744.677 126.744 746.805 131.687 746.805 137.695C746.805 143.765 744.677 148.74 740.422 152.619C736.229 156.499 730.848 158.439 724.277 158.439ZM724.277 147.269C726.718 147.269 728.72 146.424 730.285 144.735C731.849 143.045 732.631 140.698 732.631 137.695C732.631 134.754 731.849 132.438 730.285 130.749C728.72 129.059 726.718 128.214 724.277 128.214C721.837 128.214 719.803 129.059 718.176 130.749C716.611 132.438 715.829 134.754 715.829 137.695C715.829 140.636 716.611 142.983 718.176 144.735C719.803 146.424 721.837 147.269 724.277 147.269Z" fill="#505762"/>
                    <path d="M271.021 78.8006L265.219 64.3628H233.78L227.978 78.8006H206.523L239.312 4.58771H259.957L292.88 78.8006H271.021ZM239.987 48.8456H274.395L256.853 43.7181L249.432 25.0975L239.987 48.8456Z" fill="#505762"/>
                    <path d="M338.646 20.2399C343.233 20.2399 347.236 21.1394 350.655 22.9385C354.163 24.6477 356.996 27.4813 359.155 31.4393C361.314 35.3074 362.394 40.1199 362.394 45.8771V78.8006H342.154V49.1154C342.154 41.2894 339.005 37.3763 332.709 37.3763C329.29 37.3763 326.502 38.5008 324.343 40.7496C322.274 42.9985 321.239 46.4168 321.239 51.0045V70.8396C321.239 72.9985 320.475 74.8876 318.945 76.5068C317.416 78.036 315.527 78.8006 313.278 78.8006H301.134V28.3358C301.134 26.3568 301.809 24.6927 303.158 23.3433C304.598 21.994 306.262 21.3193 308.151 21.3193H320.295L312.604 34.2729C316.382 29.4153 320.61 25.8621 325.287 23.6132C329.965 21.3643 334.418 20.2399 338.646 20.2399Z" fill="#505762"/>
                    <path d="M434.808 0V73.5382C434.808 74.9775 434.313 76.2369 433.324 77.3163C432.334 78.3059 431.12 78.8006 429.681 78.8006H415.648V72.9985C411.87 77.4963 406.247 79.7451 398.781 79.7451C390.685 79.7451 383.939 77.0015 378.541 71.5143C373.144 66.027 370.445 58.8306 370.445 49.925C370.445 41.0195 373.144 33.8681 378.541 28.4708C383.939 22.9835 390.685 20.2399 398.781 20.2399C407.777 20.2399 415.558 24.1979 422.125 32.1139L414.703 21.3193V5.8021C414.703 4.18291 415.243 2.83359 416.322 1.75412C417.492 0.584708 418.886 0 420.505 0H434.808ZM402.964 63.6882C406.472 63.6882 409.351 62.4738 411.6 60.045C413.849 57.5262 414.973 54.1529 414.973 49.925C414.973 45.6972 413.849 42.3688 411.6 39.94C409.351 37.5112 406.472 36.2969 402.964 36.2969C399.456 36.2969 396.577 37.5112 394.328 39.94C392.08 42.3688 390.955 45.6972 390.955 49.925C390.955 54.2429 392.08 57.6162 394.328 60.045C396.577 62.4738 399.456 63.6882 402.964 63.6882Z" fill="#505762"/>
                    <path d="M454.385 31.8441C459.153 27.0765 463.56 23.973 467.608 22.5337C471.746 21.0045 476.379 20.2399 481.506 20.2399V38.4558C479.707 38.2759 478.223 38.1859 477.054 38.1859C472.736 38.1859 469.318 39.3553 466.799 41.6942C464.37 43.943 463.156 47.4963 463.156 52.3538V73.6732C463.156 75.1125 462.661 76.3268 461.671 77.3163C460.772 78.3059 459.602 78.8006 458.163 78.8006H443.051V26.042C443.051 24.6927 443.5 23.5682 444.4 22.6687C445.39 21.7691 446.514 21.3193 447.773 21.3193H462.211L454.385 31.8441Z" fill="#505762"/>
                    <path d="M516.286 79.7451C506.841 79.7451 499.06 76.9565 492.943 71.3793C486.826 65.7122 483.768 58.5607 483.768 49.925C483.768 41.2894 486.826 34.1829 492.943 28.6057C499.06 23.0285 506.841 20.2399 516.286 20.2399C525.732 20.2399 533.468 23.0285 539.495 28.6057C545.612 34.1829 548.67 41.2894 548.67 49.925C548.67 58.6507 545.612 65.8021 539.495 71.3793C533.468 76.9565 525.732 79.7451 516.286 79.7451ZM516.286 63.6882C519.795 63.6882 522.673 62.4738 524.922 60.045C527.171 57.6162 528.295 54.2429 528.295 49.925C528.295 45.6972 527.171 42.3688 524.922 39.94C522.673 37.5112 519.795 36.2969 516.286 36.2969C512.778 36.2969 509.855 37.5112 507.516 39.94C505.267 42.3688 504.142 45.6972 504.142 49.925C504.142 54.1529 505.267 57.5262 507.516 60.045C509.855 62.4738 512.778 63.6882 516.286 63.6882Z" fill="#505762"/>
                    <path d="M582.402 79.7451C577.545 79.7451 572.777 79.2054 568.099 78.1259C563.512 76.9565 559.779 75.5173 556.9 73.8081L560.273 65.982C560.993 64.4528 562.072 63.3733 563.512 62.7436C565.041 62.024 566.57 61.934 568.099 62.4738C572.957 64.3628 577.905 65.3074 582.942 65.3074C588.969 65.3074 591.982 64.003 591.982 61.3943C591.982 60.3149 591.353 59.5053 590.093 58.9655C588.924 58.3358 587.305 57.8861 585.236 57.6162C583.257 57.3463 581.053 57.0765 578.624 56.8066C576.195 56.4468 573.767 55.952 571.338 55.3223C568.999 54.6027 566.795 53.6582 564.726 52.4888C562.747 51.3194 561.128 49.6552 559.868 47.4963C558.699 45.2474 558.114 42.5487 558.114 39.4003C558.114 33.8231 560.588 29.2354 565.536 25.6372C570.483 22.039 577.32 20.2399 586.045 20.2399C595.311 20.2399 602.867 21.9041 608.714 25.2324L605.341 32.9235C604.621 34.4528 603.452 35.5772 601.833 36.2969C600.303 36.9265 598.684 36.9265 596.975 36.2969C593.557 35.2174 589.958 34.6777 586.18 34.6777C580.063 34.6777 577.005 35.982 577.005 38.5907C577.005 39.8501 577.815 40.8396 579.434 41.5592C581.143 42.1889 583.212 42.6387 585.641 42.9086C588.159 43.0885 590.903 43.5382 593.872 44.2579C596.84 44.8876 599.539 45.6972 601.967 46.6867C604.486 47.6762 606.555 49.3853 608.174 51.8141C609.883 54.2429 610.738 57.2564 610.738 60.8546C610.738 66.3418 608.264 70.8846 603.317 74.4828C598.369 77.991 591.398 79.7451 582.402 79.7451Z" fill="#505762"/>
                    <path d="M651.482 79.7451C642.037 79.7451 634.256 76.9565 628.139 71.3793C622.022 65.7122 618.964 58.5607 618.964 49.925C618.964 41.2894 622.022 34.1829 628.139 28.6057C634.256 23.0285 642.037 20.2399 651.482 20.2399C660.928 20.2399 668.664 23.0285 674.691 28.6057C680.808 34.1829 683.866 41.2894 683.866 49.925C683.866 58.6507 680.808 65.8021 674.691 71.3793C668.664 76.9565 660.928 79.7451 651.482 79.7451ZM651.482 63.6882C654.991 63.6882 657.869 62.4738 660.118 60.045C662.367 57.6162 663.491 54.2429 663.491 49.925C663.491 45.6972 662.367 42.3688 660.118 39.94C657.869 37.5112 654.991 36.2969 651.482 36.2969C647.974 36.2969 645.051 37.5112 642.712 39.94C640.463 42.3688 639.339 45.6972 639.339 49.925C639.339 54.1529 640.463 57.5262 642.712 60.045C645.051 62.4738 647.974 63.6882 651.482 63.6882Z" fill="#505762"/>
                    <path d="M735.929 21.3193H749.962L726.079 78.8006H705.299L681.686 21.3193H698.418C701.027 21.3193 702.826 22.5337 703.815 24.9625L713.935 50.7346L710.832 69.6252L728.778 26.042C729.317 24.6027 730.217 23.4783 731.476 22.6687C732.826 21.7691 734.31 21.3193 735.929 21.3193Z" fill="#505762"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M166 10.3535C166 8.14438 164.209 6.35352 162 6.35352H10C7.79086 6.35352 6 8.14437 6 10.3535V162.354C6 164.563 7.79086 166.354 10 166.354H162C164.209 166.354 166 164.563 166 162.354V10.3535ZM4 0.353516C1.79086 0.353516 0 2.14438 0 4.35352V168.354C0 170.563 1.79086 172.354 4 172.354H168C170.209 172.354 172 170.563 172 168.354V4.35352C172 2.14438 170.209 0.353516 168 0.353516H4Z" fill="#505762"/>
                    <path d="M122.701 20.1819C124.263 18.6198 126.796 18.6198 128.358 20.1819L152.172 43.9956C153.734 45.5577 153.734 48.0903 152.172 49.6524L128.358 73.466C126.796 75.0281 124.263 75.0281 122.701 73.466L98.8875 49.6524C97.3254 48.0903 97.3254 45.5577 98.8875 43.9956L122.701 20.1819Z" fill="#505762"/>
                    <path d="M43.642 99.241C45.2041 97.6789 47.7368 97.6789 49.2989 99.241L73.1125 123.055C74.6746 124.617 74.6746 127.149 73.1125 128.711L49.2989 152.525C47.7368 154.087 45.2041 154.087 43.642 152.525L19.8284 128.712C18.2663 127.149 18.2663 124.617 19.8284 123.055L43.642 99.241Z" fill="#505762"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M152.172 123.055C153.734 124.617 153.734 127.149 152.172 128.711L128.358 152.525C126.796 154.087 124.263 154.087 122.701 152.525L19.8286 49.6523C18.2665 48.0902 18.2665 45.5575 19.8286 43.9954L43.6422 20.1818C45.2043 18.6197 47.7369 18.6197 49.299 20.1818L152.172 123.055Z" fill="#505762"/>
                </svg>

            </Link>            
        </div>
    );


export default Logo