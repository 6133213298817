import React from 'react';
import Logo from './logo';
import Menu from './Organism/Menu/Menu';

function Header(props) {
    return (
        <header id="header">
            <Logo/>
            <Menu/>
        </header>
    );
}

export default Header