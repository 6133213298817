import React from 'react';
import * as styles from './Button.module.scss'

export function Button(props) {
    const {primary, secondary, third} = props
    const Layout = (props) => {
        if (props.href){
            return <a {...props}>{props.children}</a>;
        }
        return <button {...props}>{props.children}</button>;
    }

    let classType = "";
    if(primary){classType = styles.btn__primary};
    if(secondary){classType = styles.btn__secondary};
    if(third){classType = styles.btn__third};

    return(

            <Layout className={`${classType} ${props.className}`} href={props.href} onClick={props.onClick}>
                {props.children}
            </Layout>

    );
};