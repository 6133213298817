import React, {useEffect, useRef, useState} from 'react';
import * as styles from './DropDownList.module.scss'

const DropDownList = (props) => {
  const {header, children, className} = props
  const dropDownRef = useRef(null)


  useEffect(()=>{
    const item = dropDownRef.current

    const positions = item.getBoundingClientRect()
    const wind = window.screen
    console.log(wind.width,positions.right)
    if(positions.right + 10 > wind.width){
      item.style.right = `${20}px`
    }


  })




  return (
      <div className={`${styles.parent} ${className}`}>
        {header}
        <div className={`${styles.child}`} ref={dropDownRef}>
          {children}
        </div>
      </div>

  );
};

export default DropDownList;