import React from 'react';
import * as styles from './PageHeader.module.scss'

function Cards({cards,col}) {
    let cols
    col ? cols=`col-${col}` : cols="col-4"
    
    const cardsOut = cards.map(function(elem,index){ 
                
        return(
            <div className={styles.heroCards}>
                <div className={styles.heroCards__index}>0{index+1}</div>
                <div className={styles.heroCards__header}>{elem.name}</div>
                <dis className={styles.heroCards__desc}>{elem.desk}</dis>
            </div>
            )
    
    })

    return (
        <div className={styles.heroCardsWraper}>
            <div className={cols}>
                {cardsOut}
            </div>
        </div>
    );
}

export default Cards;